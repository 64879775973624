<!-- 分步骤add -->
<template>
  <ykc-form class="el-form-wrap" v-loading="uploadLoading">
    <el-steps :active="active" align-center finish-status="success">
      <el-step title="线索信息" />
      <el-step title="立项信息" />
      <el-step title="社区汽车信息" />
      <el-step title="竞品信息" />
    </el-steps>
    <div>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm0"
        class="el-form-wrap"
        v-show="active === 0">
        <ykc-form-item label="初次接触时间" prop="projectDTO.startTime">
          <ykc-date-picker
            :disabled="commonDisable"
            type="date"
            format="yyyy-MM-dd"
            limitDate="limitAfter"
            v-model="ruleForm.projectDTO.startTime"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="站点名称" prop="projectDTO.destination">
          <ykc-input
            :disabled="commonDisable"
            placeholder="请输入站点名称"
            maxlength="30"
            v-model="ruleForm.projectDTO.destination"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="建设年份" prop="projectDTO.year">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入建设年份"
            v-model="ruleForm.projectDTO.year"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="所属部门" prop="projectDTO.departmentId">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择所属部门"
            filterable
            :data="departmentData"
            v-model="ruleForm.projectDTO.departmentId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="BD" prop="projectDTO.bd">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择BD人员"
            filterable
            :data="bdDataOptions"
            @change="handleBdChange"
            v-model="ruleForm.projectDTO.bd"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="手机号" prop="projectDTO.phone">
          <ykc-input
            :disabled="commonDisable"
            placeholder="请输入手机号"
            maxlength="11"
            v-model="ruleForm.projectDTO.phone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="项目可见人员" prop="projectDTO.lookIds">
          <ykc-dropdown
            placeholder="请选择项目可见人员"
            filterable
            multiple
            :data="lookDataOptions"
            v-model="ruleForm.projectDTO.lookIds"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="线索状态" prop="projectDTO.clueStatus">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择线索状态"
            :data="clueStatusData"
            @change="handleClueStatusChange"
            v-model="ruleForm.projectDTO.clueStatus"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item v-if="clueReasonShow" label="原因" prop="projectDTO.clueReason">
          <ykc-input
            :disabled="commonDisable"
            type="textarea"
            placeholder="请输入线索原因"
            v-model="ruleForm.projectDTO.clueReason"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="项目来源" prop="projectDTO.projectSource">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择项目来源"
            :data="projectSourceData"
            @change="handleProjectSourceChange"
            v-model="ruleForm.projectDTO.projectSource"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item v-if="companyShow" label="公司名称" prop="projectDTO.company">
          <ykc-input
            :disabled="commonDisable"
            placeholder="请输入公司名称"
            v-model="ruleForm.projectDTO.company"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="场站性质" prop="projectDTO.stationType">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择场站性质"
            :data="stationTypeData"
            v-model="ruleForm.projectDTO.stationType"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="场地联系人姓名" prop="projectDTO.stationContactName">
          <ykc-input
            :disabled="commonDisable"
            placeholder="请输入场地联系人姓名"
            maxlength="30"
            v-model="ruleForm.projectDTO.stationContactName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="场地联系人电话" prop="projectDTO.stationContactPhone">
          <ykc-input
            :disabled="commonDisable"
            placeholder="请输入场地联系人电话"
            maxlength="11"
            v-model="ruleForm.projectDTO.stationContactPhone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="所属市区" prop="projectDTO.addressId">
          <ykc-cascader
            :disabled="commonDisable"
            :isObject="true"
            :options="addressData"
            v-model="ruleForm.projectDTO.addressId"
            @change="changeDistrict"></ykc-cascader>
        </ykc-form-item>
        <ykc-form-item label="详细地址" prop="projectDTO.detailAddress">
          <ykc-input
            :disabled="commonDisable"
            placeholder="请输入详细地址"
            maxlength="30"
            v-model="ruleForm.projectDTO.detailAddress"></ykc-input>
        </ykc-form-item>
        <ykc-form-item
          prop="projectDTO.scenePic"
          label="现场照片"
          class="file-error"
          bottomTip="温馨提示：只能上传jpg/jpeg/png文件，且不超过5M">
          <ykc-upload
            :disabled="commonDisable"
            ref="scenePic"
            :size="5"
            :limit="5"
            @handleChange="uploadImg"
            @handlePreChange="uploadImgPre"
            @handleRemove="handleRemoveImg"></ykc-upload>
        </ykc-form-item>
      </ykc-form>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-show="active === 1">
        <ykc-form-item label="项目类型" prop="projectApprovalDTO.projectType">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择项目类型"
            :data="projectTypeData"
            v-model="ruleForm.projectApprovalDTO.projectType"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="建设状态" prop="projectApprovalDTO.investStatus">
          <ykc-dropdown
            :disabled="investStatusDisabled"
            placeholder="请选择建设状态"
            :data="investStatusData"
            v-model="ruleForm.projectApprovalDTO.investStatus"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="所属物业" prop="projectApprovalDTO.propertyName">
          <ykc-autocomplete
            :disabled="commonDisable"
            placeholder="请输入所属物业"
            :fetch-suggestions="propertyNameSuggestions"
            v-model="ruleForm.projectApprovalDTO.propertyName"></ykc-autocomplete>
        </ykc-form-item>
        <ykc-form-item v-if="conPartyShow" label="施工方" prop="projectApprovalDTO.conParty">
          <ykc-input
            :disabled="conPartyDisabled"
            placeholder="请输入施工方"
            maxlength="30"
            v-model="ruleForm.projectApprovalDTO.conParty"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="车位使用率" prop="projectApprovalDTO.parkingRate">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入车位使用率"
            v-model="ruleForm.projectApprovalDTO.parkingRate">
            <template slot="append">%</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="是否需要报装" prop="projectApprovalDTO.report">
          <ykc-dropdown
            :disabled="reportDisabled"
            placeholder="请选择是否需要报装"
            :data="reportData"
            @change="handleReportChange"
            v-model="ruleForm.projectApprovalDTO.report"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item
          v-if="ruleForm.projectApprovalDTO.report === '0'"
          label="电费情况"
          prop="projectApprovalDTO.eleWay">
          <ykc-input
            :disabled="reportDisabled"
            type="number"
            placeholder="请输入电费情况"
            v-model="ruleForm.projectApprovalDTO.eleWay">
            <template slot="append">元/度</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item
          v-if="ruleForm.projectApprovalDTO.report === '1'"
          label="拟报装电容"
          prop="projectApprovalDTO.capacity">
          <ykc-input
            :disabled="reportDisabled"
            type="number"
            placeholder="请输入拟报装电容"
            v-model="ruleForm.projectApprovalDTO.capacity">
            <template slot="append">KW</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item v-if="attachmentShow" label="报装材料" prop="projectApprovalDTO.attachment">
          <ykc-upload
            type="files"
            :limit="10"
            :size="50"
            :disabled="attachmentDisabled"
            ref="attachment"
            @handleChange="uploadAttachment"
            @handlePreChange="uploadAttachmentPre"
            @handleRemove="handleRemoveAttachment"></ykc-upload>
        </ykc-form-item>
        <ykc-form-item label="目的地户数" prop="projectApprovalDTO.destNum">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入目的地户数"
            v-model="ruleForm.projectApprovalDTO.destNum"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="目的地停车位数量" prop="projectApprovalDTO.destParkingNum">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入目的地停车位数量"
            v-model="ruleForm.projectApprovalDTO.destParkingNum"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="拟签约年限" prop="projectApprovalDTO.signYear">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入拟签约年限"
            v-model="ruleForm.projectApprovalDTO.signYear"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="物业条款(分佣)" prop="projectApprovalDTO.propertyTerms">
          <ykc-input
            :disabled="commonDisable"
            placeholder="请输入物业条款(分佣)"
            v-model="ruleForm.projectApprovalDTO.propertyTerms"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="目的地停车位属性(临停)" prop="projectApprovalDTO.parkingStopNum">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入临停停车位数量"
            v-model="ruleForm.projectApprovalDTO.parkingStopNum">
            <template slot="append">个</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item
          label="目的地停车位属性(物业租赁)"
          prop="projectApprovalDTO.parkingPropertyNum">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入物业租赁停车位数量"
            v-model="ruleForm.projectApprovalDTO.parkingPropertyNum">
            <template slot="append">个</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="目的地停车位属性(产权)" prop="projectApprovalDTO.parkingRightNum">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入产权停车位数量"
            v-model="ruleForm.projectApprovalDTO.parkingRightNum">
            <template slot="append">个</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="通讯信号" prop="projectApprovalDTO.commSignal">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择通讯信号"
            :data="commSignalData"
            v-model="ruleForm.projectApprovalDTO.commSignal"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="现场照明" prop="projectApprovalDTO.lighting">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择现场照明"
            :data="lightingData"
            v-model="ruleForm.projectApprovalDTO.lighting"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="运营商名称" prop="projectApprovalDTO.operatorIds">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择运营商名称"
            multiple
            :data="operatorIdData"
            v-model="ruleForm.projectApprovalDTO.operatorIds"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="取电主开关" :required="isRequired">
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">电流</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectApprovalDTO.eleFlow">
                  <ykc-input
                    :disabled="commonDisable"
                    type="number"
                    placeholder="请输入电流"
                    v-model="ruleForm.projectApprovalDTO.eleFlow">
                    <template slot="append">A</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col class="cus-inline-split" :span="2">或</el-col>
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">功率</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectApprovalDTO.power">
                  <ykc-input
                    :disabled="commonDisable"
                    type="number"
                    placeholder="请输入功率"
                    v-model="ruleForm.projectApprovalDTO.power">
                    <template slot="append">KW</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
        </ykc-form-item>
        <ykc-form-item label="安装环境" prop="projectApprovalDTO.env">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择安装环境"
            :data="envData"
            v-model="ruleForm.projectApprovalDTO.env"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="安装基础" prop="projectApprovalDTO.base">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择安装基础"
            :data="baseData"
            v-model="ruleForm.projectApprovalDTO.base"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="配电箱安装基础" prop="projectApprovalDTO.eleBoxBase">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择配电箱安装基础"
            :data="eleBoxBaseData"
            v-model="ruleForm.projectApprovalDTO.eleBoxBase"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item
          label="主电缆距离(从取电点至充电车位的距离)"
          prop="projectApprovalDTO.distance">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入主电缆距离"
            v-model="ruleForm.projectApprovalDTO.distance">
            <template slot="append">米</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="场地方是否同意铝合金电缆" prop="projectApprovalDTO.alloyCable">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择场地方是否同意铝合金电缆"
            :data="alloyCableData"
            v-model="ruleForm.projectApprovalDTO.alloyCable"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="私桩数量" prop="projectApprovalDTO.privatePileNum">
          <ykc-input
            :disabled="commonDisable"
            type="number"
            placeholder="请输入私桩数量"
            v-model="ruleForm.projectApprovalDTO.privatePileNum">
            <template slot="append">个</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="是否存在飞线情况" prop="projectApprovalDTO.flyingLine">
          <ykc-dropdown
            :disabled="commonDisable"
            placeholder="请选择是否存在飞线情况"
            :data="flyingLineData"
            v-model="ruleForm.projectApprovalDTO.flyingLine"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="项目情况介绍" prop="projectApprovalDTO.intro">
          <ykc-input
            :disabled="commonDisable"
            maxlength="500"
            type="textarea"
            placeholder="请输入项目情况介绍"
            v-model="ruleForm.projectApprovalDTO.intro"></ykc-input>
        </ykc-form-item>
      </ykc-form>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        :disabled="commonDisable"
        ref="ruleForm2"
        class="el-form-wrap"
        v-show="active === 2">
        <ykc-form-item label="电动汽车总数量" prop="projectCarDTO.eleCarNum">
          <ykc-input
            type="number"
            placeholder="请输入电动汽车总数量"
            v-model="ruleForm.projectCarDTO.eleCarNum">
            <template slot="append">辆</template>
          </ykc-input>
        </ykc-form-item>
        <el-row>
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">地面数量</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectCarDTO.eleCarUpNum">
                  <ykc-input
                    type="number"
                    placeholder="请输入地面数量"
                    v-model="ruleForm.projectCarDTO.eleCarUpNum">
                    <template slot="append">辆</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col class="cus-inline-split" :span="2"></el-col>
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">地下数量</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectCarDTO.eleCarDownNum">
                  <ykc-input
                    type="number"
                    placeholder="请输入地下数量"
                    v-model="ruleForm.projectCarDTO.eleCarDownNum">
                    <template slot="append">辆</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <ykc-form-item label="纯电动汽车数量" prop="projectCarDTO.pureCarNum">
          <ykc-input
            type="number"
            placeholder="请输入纯电动汽车数量"
            v-model="ruleForm.projectCarDTO.pureCarNum">
            <template slot="append">辆</template>
          </ykc-input>
        </ykc-form-item>
        <el-row>
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">地面数量</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectCarDTO.pureCarUpNum">
                  <ykc-input
                    type="number"
                    placeholder="请输入地面数量"
                    v-model="ruleForm.projectCarDTO.pureCarUpNum">
                    <template slot="append">辆</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col class="cus-inline-split" :span="2"></el-col>
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">地下数量</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectCarDTO.pureCarDownNum">
                  <ykc-input
                    type="number"
                    placeholder="请输入地下数量"
                    v-model="ruleForm.projectCarDTO.pureCarDownNum">
                    <template slot="append">辆</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <ykc-form-item label="油车数量" prop="projectCarDTO.oilCarNum">
          <ykc-input
            type="number"
            placeholder="请输入电动汽车总数量"
            v-model="ruleForm.projectCarDTO.oilCarNum">
            <template slot="append">辆</template>
          </ykc-input>
        </ykc-form-item>
        <el-row>
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">地面数量</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectCarDTO.oilCarUpNum">
                  <ykc-input
                    type="number"
                    placeholder="请输入地面数量"
                    v-model="ruleForm.projectCarDTO.oilCarUpNum">
                    <template slot="append">辆</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col class="cus-inline-split" :span="2"></el-col>
          <el-col :span="11">
            <el-row>
              <el-col class="cus-inline-label" :span="6">地下数量</el-col>
              <el-col :span="18">
                <ykc-form-item prop="projectCarDTO.oilCarDownNum">
                  <ykc-input
                    type="number"
                    placeholder="请输入地下数量"
                    v-model="ruleForm.projectCarDTO.oilCarDownNum">
                    <template slot="append">辆</template>
                  </ykc-input>
                </ykc-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <ykc-form-item label="电动出租车/网约车数量" prop="projectCarDTO.taxiNum">
          <ykc-input
            type="number"
            placeholder="请输入电动出租车/网约车数量"
            v-model="ruleForm.projectCarDTO.taxiNum">
            <template slot="append">辆</template>
          </ykc-input>
        </ykc-form-item>
        <ykc-form-item label="是否需要指示牌" prop="projectCarDTO.signage">
          <ykc-dropdown
            placeholder="请选择是否需要指示牌"
            :data="signageData"
            v-model="ruleForm.projectCarDTO.signage"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="是否需要其他物料" prop="projectCarDTO.otherMaterial">
          <ykc-dropdown
            placeholder="请选择是否需要其他物料"
            :data="otherMaterialData"
            v-model="ruleForm.projectCarDTO.otherMaterial"></ykc-dropdown>
        </ykc-form-item>
      </ykc-form>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        :disabled="commonDisable"
        ref="ruleForm3"
        class="el-form-wrap"
        v-show="active === 3">
        <ykc-form-item label="是否存在竞争对手" prop="projectCompeteDTO.compete">
          <ykc-dropdown
            placeholder="请选择是否存在竞争对手"
            :data="competeData"
            @change="handleCompeteChange"
            v-model="ruleForm.projectCompeteDTO.compete"></ykc-dropdown>
        </ykc-form-item>
        <template v-if="competeShow">
          <div
            v-for="(item, index) in ruleForm.projectCompeteDTO.competeData"
            :key="item.key"
            class="compete-wrap">
            <div class="header">
              <div class="compete-title">{{ `竞争对手 ${index + 1}` }}</div>
              <div
                v-if="
                  !commonDisable &&
                  ruleForm.projectCompeteDTO.competeData &&
                  ruleForm.projectCompeteDTO.competeData.length > 1
                "
                class="compete-delete"
                @click="handleDeleteCompete(index)">
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <div class="content">
              <ykc-form-item
                label="竞争对手名称"
                :prop="`projectCompeteDTO.competeData.${index}.name`"
                :rules="{
                  required: isRequired,
                  message: '请输入竞争对手名称',
                  trigger: 'blur',
                }">
                <ykc-input placeholder="请输入竞争对手名称" v-model="item.name"></ykc-input>
              </ykc-form-item>
              <ykc-form-item
                label="竞争对手桩数量"
                :prop="`projectCompeteDTO.competeData.${index}.num`"
                :rules="[
                  {
                    required: isRequired,
                    message: '请输入竞争对手桩数量',
                    trigger: 'blur',
                  },
                  {
                    trigger: 'blur',
                    pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                    message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
                  },
                ]">
                <ykc-input
                  type="number"
                  placeholder="请输入竞争对手桩数量"
                  v-model="item.num"></ykc-input>
              </ykc-form-item>
              <ykc-form-item
                label="竞争对手电价"
                :prop="`projectCompeteDTO.competeData.${index}.elePrice`"
                :rules="[
                  {
                    required: isRequired,
                    message: '请输入竞争对手电价',
                    trigger: 'blur',
                  },
                  {
                    trigger: 'blur',
                    pattern: regexpObj.regexp.input.priceNumberType.regexp,
                    message: regexpObj.regexp.input.priceNumberType.errorTips.error(
                      '电价',
                      '999.9999'
                    ),
                  },
                ]">
                <ykc-input type="number" placeholder="请输入竞争对手电价" v-model="item.elePrice">
                  <template slot="append">元/度</template>
                </ykc-input>
              </ykc-form-item>
              <ykc-form-item
                label="竞争对手服务费"
                :prop="`projectCompeteDTO.competeData.${index}.eleService`"
                :rules="[
                  {
                    required: isRequired,
                    message: '请输入竞争对手服务费',
                    trigger: 'blur',
                  },
                  {
                    trigger: 'blur',
                    pattern: regexpObj.regexp.input.priceNumberType.regexp,
                    message: regexpObj.regexp.input.priceNumberType.errorTips.error(
                      '服务费',
                      '999.9999'
                    ),
                  },
                ]">
                <ykc-input
                  type="number"
                  placeholder="请输入竞争对手服务费"
                  v-model="item.eleService">
                  <template slot="append">元/度</template>
                </ykc-input>
              </ykc-form-item>
            </div>
          </div>
          <div v-if="!commonDisable" class="compete-add" @click="handleAddCompete">
            <i class="el-icon-plus add-btn"></i>
            <span>添加竞争对手</span>
          </div>
        </template>

        <ykc-form-item label="图片资料" prop="projectCompeteDTO.picData">
          <ykc-upload
            :disabled="commonDisable"
            type="files"
            :limit="10"
            :size="50"
            ref="picData"
            :checkInfo="{
              typeMess: '文件格式错误，请重新导入',
              sizeMess: '文件大小不超过',
            }"
            @handleChange="uploadPicData"
            @handlePreChange="uploadPicDataPre"
            @handleRemove="handleRemovePicData"></ykc-upload>
        </ykc-form-item>
        <ykc-form-item label="投建模型建议投建数量">
          <el-row>
            <el-col :span="16">
              <ykc-form-item prop="projectCompeteDTO.num">
                <ykc-input
                  disabled
                  type="number"
                  placeholder="投建模型建议投建数量"
                  v-model="ruleForm.projectCompeteDTO.num"></ykc-input>
              </ykc-form-item>
            </el-col>
            <el-col :span="6" :offset="1">
              <ykc-button type="primary" @click="handleCalcPileCount">模型计算</ykc-button>
            </el-col>
          </el-row>
        </ykc-form-item>
        <ykc-form-item label="首批投建数量" prop="projectCompeteDTO.firstNum">
          <ykc-input
            type="number"
            placeholder="请输入首批投建数量"
            v-model="ruleForm.projectCompeteDTO.firstNum"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="签约投建数量" prop="projectCompeteDTO.signNum">
          <ykc-input
            type="number"
            placeholder="请输入签约投建数量"
            v-model="ruleForm.projectCompeteDTO.signNum"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import dayjs from 'dayjs';
  import regexpObj from '@/utils/regexp';
  import { sessionGetItem } from '@/utils';
  import { treeUtils, icProject, accountManage, uploadUtils } from '@/service/apis';
  import { loadDicItem } from '@/utils/dictionary';
  import {
    stationTypeData,
    clueStatusData,
    reportData,
    commSignalData,
    lightingData,
    operatorIdData,
    baseData,
    eleBoxBaseData,
    alloyCableData,
    flyingLineData,
    signageData,
    otherMaterialData,
    competeData,
  } from './constants';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        regexpObj,
        uploadLoading: false,
        geocoder: undefined, // 地图解析对象
        bdData: [],
        lookData: [],
        clueStatusData,
        projectSourceData: [],
        stationTypeData,
        addressData: [], // 区数据源
        projectTypeData: [],
        investStatusData: [],
        propertyNameData: [{ value: '森通' }, { value: '工业园' }], // 所属物业数据
        reportData,
        commSignalData,
        lightingData,
        operatorIdData,
        envData: [],
        baseData,
        eleBoxBaseData,
        alloyCableData,
        flyingLineData,
        signageData,
        otherMaterialData,
        competeData,
        departmentData: [],
        ruleForm: {
          projectDTO: {
            startTime: dayjs().format('YYYY-MM-DD'),
            destination: '',
            year: undefined,
            departmentId: '',
            bd: '',
            phone: undefined,
            lookIds: undefined, // 初始化为[],会导致第一次进入没有选项
            clueStatus: '',
            clueReason: '',
            projectSource: '',
            company: '',
            stationType: '',
            stationContactName: '',
            stationContactPhone: '',
            addressId: [],
            provinceId: '',
            cityId: '',
            districtId: '',
            detailAddress: '',
            scenePic: [],
          },

          projectApprovalDTO: {
            attachment: [],
            projectType: '',
            investStatus: '',
            propertyName: '',
            conParty: '',
            parkingRate: '',
            report: '',
            eleWay: '',
            capacity: '',
            destNum: '',
            destParkingNum: '',
            signYear: '',
            propertyTerms: '',
            parkingStopNum: '',
            parkingPropertyNum: '',
            parkingRightNum: '',
            commSignal: '',
            lighting: '',
            operatorIds: undefined,
            eleFlow: '',
            power: '',
            env: '',
            base: '',
            eleBoxBase: '',
            distance: '',
            alloyCable: '',
            privatePileNum: '',
            flyingLine: '',
            intro: '',
          },

          projectCarDTO: {
            eleCarNum: '',
            eleCarUpNum: '',
            eleCarDownNum: '',
            pureCarNum: '',
            pureCarUpNum: '',
            pureCarDownNum: '',
            oilCarNum: '',
            oilCarUpNum: '',
            oilCarDownNum: '',
            taxiNum: '',
            signage: '',
            otherMaterial: '',
          },

          projectCompeteDTO: {
            picData: [],
            compete: '',
            num: '',
            firstNum: '',
            signNum: '',
            competeData: [],
          },
        },
      };
    },
    created() {
      this.projectSourceData = loadDicItem('support_project_source');
      this.projectTypeData = loadDicItem('support_project_type');
      this.investStatusData = loadDicItem('support_invest_status');
      this.envData = loadDicItem('support_env');
      /* eslint-disable no-undef */
      this.geocoder = new AMap.Geocoder({ radius: 1000, extensions: 'all' });
      // 获取地区下拉树
      this.requestGetDistrict();
      this.requestDepartment();
      this.requestBdData();
      this.requestLookData();
      this.getPropertyListDown();

      // 编辑回显
      if (this.id) {
        icProject
          .getSupportProjectOne({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
            this.ruleForm.projectDTO.addressId = [
              Number(this.ruleForm.projectDTO.provinceId),
              Number(this.ruleForm.projectDTO.cityId),
              Number(this.ruleForm.projectDTO.districtId),
            ];

            this.$nextTick(() => {
              // $ref在渲染后才会生成,created在挂载过程,需要使用nextTick在渲染后执行
              // 回显scenePic
              if (this.$refs.scenePic) {
                this.$refs.scenePic.fileList = [...this.ruleForm.projectDTO.scenePic];
              }

              if (this.$refs.attachment) {
                this.$refs.attachment.fileList = [...this.ruleForm.projectApprovalDTO.attachment];
              }

              if (this.$refs.picData) {
                this.$refs.picData.fileList = [...this.ruleForm.projectCompeteDTO.picData];
              }

              // 清除回显数据造成的验证状态, 必须在$nextTick下才能清除
              // 可能原因是此处又有赋值,需要在最后一次赋值之后清除状态?
              this.$refs.ruleForm0.clearValidate();
              this.$refs.ruleForm1.clearValidate();
              this.$refs.ruleForm2.clearValidate();
              this.$refs.ruleForm3.clearValidate();
            });
            // 回显
          })
          .catch(() => {});
      }
    },
    computed: {
      // 通用编辑不能修改状态
      commonDisable() {
        const statusArr = ['10010', '10011'];
        return this.isEdit && !statusArr.includes(this.ruleForm.projectDTO.status);
      },
      // 建设状态是否可编辑
      investStatusDisabled() {
        const statusArr = ['10020', '10021'];
        return this.isEdit && statusArr.includes(this.ruleForm.projectDTO.status);
      },
      // 是否需要报装编辑态
      reportDisabled() {
        const containerStatus = ['10017', '10018', '10019', '10020', '10021'];
        return this.isEdit && containerStatus.includes(this.ruleForm.projectDTO.status);
      },
      // 施工方名称是否显示
      conPartyShow() {
        const showStatus = [
          '10013',
          '10014',
          '10015',
          '10016',
          '10017',
          '10018',
          '10019',
          '10020',
          '10021',
        ];
        return this.isEdit && showStatus.includes(this.ruleForm.projectDTO.status);
      },
      // 施工是否可编辑
      conPartyDisabled() {
        return !(this.isEdit && this.ruleForm.projectDTO.status === '10013');
      },

      // 报装材料是否显示
      attachmentShow() {
        const showStatus = ['10016', '10017', '10018', '10019', '10020', '10021'];
        return (
          this.isEdit &&
          this.ruleForm.projectApprovalDTO.report === '1' &&
          showStatus.includes(this.ruleForm.projectDTO.status)
        );
      },
      // 报装材料是否编辑
      attachmentDisabled() {
        return !(this.isEdit && this.ruleForm.projectDTO.status === '10016');
      },
      /**
       * 线索原因是否显示
       */
      clueReasonShow() {
        return (
          this.ruleForm.projectDTO.clueStatus === '1' || this.ruleForm.projectDTO.clueStatus === '2'
        );
      },
      /**
       * 公司名称是否显示
       */
      companyShow() {
        return (
          this.ruleForm.projectDTO.projectSource === '2' ||
          this.ruleForm.projectDTO.projectSource === '4'
        );
      },
      /**
       * 是否显示竞争对手
       */
      competeShow() {
        return this.ruleForm.projectCompeteDTO.compete === '1';
      },
      /**
       * 竞争对手桩总数
       */
      competitionCount() {
        let result = 0;
        if (
          !this.ruleForm.projectCompeteDTO.competeData ||
          !this.ruleForm.projectCompeteDTO.competeData.length
        ) {
          return result;
        }

        this.ruleForm.projectCompeteDTO.competeData.forEach(item => {
          result += Number(item.num || 0);
        });

        return result;
      },
      /**
       * bd 数据
       */
      bdDataOptions() {
        return this.bdData.map(item => ({
          id: item.id,
          name: `${item.accountName}(${item.accountNo})`,
        }));
      },

      /**
       * looksId 数据
       */
      lookDataOptions() {
        return this.lookData.map(item => ({
          id: item.id,
          name: `${item.accountName}(${item.accountNo})`,
        }));
      },

      // 新增和初次拜访去掉部分验证
      isRequired() {
        return this.isEdit && this.ruleForm?.projectDTO?.status !== '10010';
      },

      rules() {
        return {
          projectDTO: {
            startTime: [{ required: true, message: '请选择初次接触时间', trigger: 'blur' }],
            destination: [{ required: true, message: '请输入站点名称', trigger: 'blur' }],
            year: [
              { required: true, message: '请输入建设年份', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(),
              },
            ],
            departmentId: [{ required: true, message: '请选择所属部门', trigger: 'blur' }],
            bd: [{ required: true, message: '请选择BD人员', trigger: 'blur' }],
            phone: [
              { required: true, message: '请输入手机号', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.cellphoneType.regexp,
                message: regexpObj.regexp.input.cellphoneType.errorTips.error('手机号'),
              },
            ],
            lookIds: [
              { type: 'array', required: true, message: '请选择项目可见人员', trigger: 'blur' },
            ],
            clueStatus: [{ required: true, message: '请选择线索状态', trigger: 'blur' }],
            clueReason: [{ required: true, message: '请输入线索原因', trigger: 'blur' }],
            projectSource: [{ required: true, message: '请选择项目来源', trigger: 'blur' }],
            company: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
            stationType: [{ required: true, message: '请选择场站性质', trigger: 'blur' }],
            stationContactName: [
              { required: true, message: '请输入场地联系人姓名', trigger: 'blur' },
            ],
            stationContactPhone: [
              { required: true, message: '请输入场地联系人电话', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.cellphoneType.regexp,
                message: regexpObj.regexp.input.cellphoneType.errorTips.error('场地联系人电话'),
              },
            ],
            addressId: [
              { type: 'array', required: true, message: '请选择省市区', trigger: 'blur' },
            ],
            detailAddress: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
            scenePic: [
              { type: 'array', required: true, message: '请上传现场照片', trigger: 'blur' },
            ],
          },
          projectApprovalDTO: {
            attachment: [
              {
                type: 'array',
                required: this.isRequired,
                message: '请上传报装材料',
                trigger: 'blur',
              },
            ],
            projectType: [
              { required: this.isRequired, message: '请选择项目类型', trigger: 'blur' },
            ],
            propertyName: [
              { required: this.isRequired, message: '请输入所属物业', trigger: 'blur' },
            ],
            investStatus: [
              { required: this.isRequired, message: '请选择建设状态', trigger: 'blur' },
            ],
            conParty: [{ required: this.isRequired, message: '请输入施工方', trigger: 'blur' }],
            parkingRate: [
              { required: this.isRequired, message: '请输入车位使用率', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                message:
                  regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('车位使用率'),
              },
            ],
            report: [{ required: this.isRequired, message: '请选择是否报装', trigger: 'blur' }],
            eleWay: [
              { required: this.isRequired, message: '请输入电费情况', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                message:
                  regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('拟报装电容'),
              },
            ],
            capacity: [
              { required: this.isRequired, message: '请输入拟报装电容', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                message:
                  regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('拟报装电容'),
              },
            ],
            destNum: [
              { required: this.isRequired, message: '请输入目的地户数', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(),
              },
            ],
            destParkingNum: [
              { required: this.isRequired, message: '请输入目的地停车位数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(),
              },
            ],
            signYear: [
              { required: this.isRequired, message: '请输入拟签约年限', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                message:
                  regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('拟签约年限'),
              },
            ],
            propertyTerms: [
              { required: this.isRequired, message: '请输入物业条款(分佣)', trigger: 'blur' },
            ],
            parkingStopNum: [
              { required: this.isRequired, message: '请输入临停停车位数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            parkingPropertyNum: [
              {
                required: this.isRequired,
                message: '请输入物业租赁停车位数量',
                trigger: 'blur',
              },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            parkingRightNum: [
              { required: this.isRequired, message: '请输入产权停车位数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            commSignal: [{ required: this.isRequired, message: '请选择通讯信号', trigger: 'blur' }],
            lighting: [{ required: this.isRequired, message: '请选择现场照明', trigger: 'blur' }],
            operatorIds: [
              {
                type: 'array',
                required: this.isRequired,
                message: '请选择运营商名称',
                trigger: 'blur',
              },
            ],
            eleFlow: [
              { validator: this.validateEleFlowAndPower, trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                message: regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('电流'),
              },
            ],
            power: [
              { validator: this.validateEleFlowAndPower, trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                message: regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('功率'),
              },
            ],
            env: [{ required: this.isRequired, message: '请选择安装环境', trigger: 'blur' }],
            base: [{ required: this.isRequired, message: '请选择安装基础', trigger: 'blur' }],
            eleBoxBase: [
              { required: this.isRequired, message: '请选择配电箱安装基础', trigger: 'blur' },
            ],
            distance: [
              { required: this.isRequired, message: '请输入主电缆距离', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                message:
                  regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('主电缆距离'),
              },
            ],
            alloyCable: [
              {
                required: this.isRequired,
                message: '请选择场地方是否同意铝合金电缆',
                trigger: 'blur',
              },
            ],
            privatePileNum: [
              { required: this.isRequired, message: '请输入私桩数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            flyingLine: [
              { required: this.isRequired, message: '请选择是否存在飞线情况', trigger: 'blur' },
            ],
          },
          projectCarDTO: {
            eleCarNum: [
              { required: this.isRequired, message: '请输入电动汽车总数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            eleCarUpNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            eleCarDownNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            pureCarNum: [
              { required: this.isRequired, message: '请输入纯电车总数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            pureCarUpNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            pureCarDownNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            oilCarNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            oilCarUpNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            oilCarDownNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
            taxiNum: [
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
                message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(),
              },
            ],
          },
          projectCompeteDTO: {
            picData: [
              {
                type: 'array',
                required: this.isRequired,
                message: '请上传图片资料',
                trigger: 'blur',
              },
            ],
            compete: [
              { required: this.isRequired, message: '请选择是否存在竞争对手', trigger: 'blur' },
            ],
            num: [
              {
                required: this.isRequired,
                message: '投建模型建议投建数量不能为空',
                trigger: 'blur',
              },
            ],
            firstNum: [
              { required: this.isRequired, message: '请输入首批投建数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(),
              },
            ],
            signNum: [
              { required: this.isRequired, message: '请输入签约投建数量', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(),
              },
            ],
          },
        };
      },
      formData() {
        const formData = {
          ...this.ruleForm,
        };
        formData.projectDTO.departmentName = this.departmentData.filter(
          item => item.id === this.ruleForm.projectDTO.departmentId
        )[0].name;
        formData.id = Number(formData.id);
        console.log(JSON.stringify(formData));

        return formData;
      },
    },
    methods: {
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取所属部门数据
       */
      requestDepartment() {
        accountManage
          .getDepartmentList({})
          .then(res => {
            if (res) {
              this.departmentData = res;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取bd用户
       */
      requestBdData() {
        accountManage
          .getUserAccountByBd()
          .then(res => {
            if (res) {
              this.bdData = res;
              if (!this.isEdit) {
                // 新建状态, 当前用户为bd,则默认选中
                const accountId = sessionGetItem('accountId');
                const current = this.bdData.filter(item => item.id === Number(accountId))[0];
                if (current) {
                  this.ruleForm.projectDTO.bd = current.id;
                  this.ruleForm.projectDTO.phone = current.phone;
                }
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      requestLookData() {
        accountManage
          .getUserAccountAll()
          .then(res => {
            if (res) {
              this.lookData = res;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      // 获取物业数据
      getPropertyListDown() {
        icProject
          .getPropertyListDown({})
          .then(res => {
            if (res) {
              this.propertyNameData = (res || []).map(item => ({ value: item.propertyName }));
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取所有用户
       */
      /**
       * 获取需要的城市数据
       */
      changeDistrict(city) {
        console.log('city', city);
        this.ruleForm.projectDTO.provinceId = city[0].id;
        this.ruleForm.projectDTO.cityId = city[1].id;
        this.ruleForm.projectDTO.districtId = city[2].id;
      },
      uploadImgPre() {
        this.uploadLoading = true;
      },
      uploadImg(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          this.uploadLoading = true;
          uploadUtils
            .uploadImgSyncFeishu(formData)
            .then(res => {
              // 使用上传后成功后的数据更新fileList
              if (!this.ruleForm.projectDTO.scenePic) {
                this.ruleForm.projectDTO.scenePic = [];
              }
              this.ruleForm.projectDTO.scenePic.push({
                name: file.name,
                fsFileCode: res.fsFileCode,
                url: res.ossFileUrl,
              });
              // 更新上传后的列表文件, 引用类型, 不能直接赋值
              this.$refs.scenePic.fileList = [...this.ruleForm.projectDTO.scenePic];
              this.$refs.ruleForm0.validateField('projectDTO.scenePic');
            })
            .finally(() => {
              this.uploadLoading = false;
            });
        } else {
          this.uploadLoading = false;
        }
      },
      handleRemoveImg(file) {
        // 编辑和新增时,都以url为唯一标识
        console.log('remove img', file);
        this.ruleForm.projectDTO.scenePic = this.ruleForm.projectDTO.scenePic?.filter(
          item => item.url !== file.url
        );
      },
      uploadAttachmentPre() {
        this.uploadLoading = true;
      },
      uploadAttachment(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          this.uploadLoading = true;
          uploadUtils
            .uploadFile(formData)
            .then(res => {
              // 多记录一个file.uid,用来删除时,删除对应的file
              console.log('upload att', res);
              if (!this.ruleForm.projectApprovalDTO.attachment) {
                this.ruleForm.projectApprovalDTO.attachment = [];
              }
              this.ruleForm.projectApprovalDTO.attachment.push({
                name: file.name,
                url: res,
              });
              this.$refs.attachment.fileList = [...this.ruleForm.projectApprovalDTO.attachment];
              this.$refs.ruleForm1.validateField('projectApprovalDTO.attachment');
            })
            .finally(() => {
              this.uploadLoading = false;
            });
        } else {
          this.uploadLoading = false;
        }
      },
      handleRemoveAttachment(file) {
        console.log('remove att', file);
        this.ruleForm.projectApprovalDTO.attachment =
          this.ruleForm.projectApprovalDTO.attachment?.filter(item => item.url !== file.url);
      },
      uploadPicDataPre() {
        this.uploadLoading = true;
      },
      uploadPicData(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param action', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils
            .uploadFileSyncFeishu(formData)
            .then(res => {
              // 多记录一个file.uid,用来删除时,删除对应的file
              console.log('upload att', res);
              if (!this.ruleForm.projectCompeteDTO.picData) {
                this.ruleForm.projectCompeteDTO.picData = [];
              }
              this.ruleForm.projectCompeteDTO.picData.push({
                name: file.name,
                fsFileCode: res.fsFileCode,
                url: res.ossFileUrl,
              });
              this.$refs.picData.fileList = [...this.ruleForm.projectCompeteDTO.picData];
              this.$refs.ruleForm3.validateField('projectCompeteDTO.picData');
            })
            .finally(() => {
              this.uploadLoading = false;
            });
        } else {
          this.uploadLoading = false;
        }
      },
      handleRemovePicData(file) {
        console.log('remove att', file);
        this.ruleForm.projectCompeteDTO.picData = this.ruleForm.projectCompeteDTO.picData?.filter(
          item => item.url !== file.url
        );
      },
      propertyNameSuggestions(qs, cb) {
        const filterData = this.propertyNameData.filter(item => item.value.includes(qs));
        return cb(filterData);
      },

      /**
       * 校验取电主开关电流或者功率
       */
      validateEleFlowAndPower(rule, value, callback) {
        if (
          !this.ruleForm.projectApprovalDTO.eleFlow &&
          !this.ruleForm.projectApprovalDTO.power &&
          this.isRequired
        ) {
          callback(new Error('取电主开关电流和功率不能同时为空'));
        } else {
          callback();
        }
      },
      /**
       * 模型计算
       */
      handleCalcPileCount() {
        icProject
          .getConstructionCount({
            eleCarNum: this.ruleForm.projectCarDTO.eleCarNum,
            privatePileNum: this.ruleForm.projectApprovalDTO.privatePileNum,
            competitionCount: this.competitionCount,
          })
          .then(res => {
            this.ruleForm.projectCompeteDTO.num = res;
          })
          .catch(err => {
            // this.$message.error(err);
            this.ruleForm.projectCompeteDTO.num = 0;
            console.error(err);
          });
      },

      /**
       * 清除文件
       */
      handleRemove(propName) {
        this.ruleForm[propName] = [];
      },
      // 新增竞争对手
      handleAddCompete() {
        this.ruleForm.projectCompeteDTO.competeData.push({
          name: '',
          num: undefined,
          elePrice: undefined,
          eleService: undefined,
          key: Date.now(),
        });
      },
      // 删除竞争对手
      handleDeleteCompete(index) {
        this.ruleForm.projectCompeteDTO.competeData.splice(index, 1);
      },
      // bd改变
      handleBdChange(value) {
        this.ruleForm.projectDTO.phone =
          this.bdData.filter(item => item.id === value)[0]?.phone || '';
      },
      // 线索状态
      handleClueStatusChange(value) {
        if (value === '3') {
          this.ruleForm.projectDTO.clueReason = '';
        }
      },
      // 项目来源
      handleProjectSourceChange(value) {
        if (value !== '2') {
          // 不是渠道居间方,清空数据
          this.ruleForm.projectDTO.company = '';
        }
      },
      // 是否需要报装
      handleReportChange(value) {
        if (value === '1') {
          // 切换到报装,清空电费
          this.ruleForm.projectApprovalDTO.eleWay = '';
        } else {
          this.ruleForm.projectApprovalDTO.capacity = '';
        }
      },
      // 是否存在竞争对手改变
      handleCompeteChange(value) {
        if (value === '1') {
          // 修改为存在竞争对手
          this.ruleForm.projectCompeteDTO.competeData = [
            {
              name: '',
              num: undefined,
              elePrice: undefined,
              eleService: undefined,
              key: Date.now(),
            },
          ];
        } else {
          // 修改为不存在竞争对手
          this.ruleForm.projectCompeteDTO.competeData = [];
        }
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      saveForm() {
        return new Promise((resolve, reject) => {
          icProject
            .edit({ ...this.formData, opType: 0 })
            .then(res => {
              console.log('编辑', res);
              this.$message({
                message: '保存成功',
                type: 'success',
              });
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },

      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.validateAllForm()
            .then(() => {
              if (!this.id) {
                icProject
                  .add(this.formData)
                  .then(res => {
                    console.log('新增', res);
                    this.$message({
                      message: '提交成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              } else {
                // eslint-disable-next-line no-lonely-if
                if (this.ruleForm.projectDTO.status === '10019') {
                  const provinceData = this.addressData.filter(
                    item => item.id === Number(this.ruleForm.projectDTO.provinceId)
                  )[0];
                  const cityDate = provinceData.subNodes.filter(
                    item => item.id === Number(this.ruleForm.projectDTO.cityId)
                  )[0];
                  const districtDate = cityDate.subNodes.filter(
                    item => item.id === Number(this.ruleForm.projectDTO.districtId)
                  )[0];
                  const address = `${provinceData.name}${cityDate.name}${districtDate.name}${this.ruleForm.projectDTO.detailAddress}`;
                  this.geocoder.getLocation(address, (status, result) => {
                    if (status === 'complete' && result.info === 'OK') {
                      this.handleEdit(resolve, reject, {
                        latitude: result.geocodes[0].location.lat || 0,
                        longitude: result.geocodes[0].location.lng || 0,
                      });
                    } else {
                      console.log('定位失败');
                      this.handleEdit(resolve, reject);
                    }
                  });
                } else {
                  this.handleEdit(resolve, reject);
                }
              }
            })
            .catch(err => {
              console.log('validate err', err);
              reject(err);
            });
        });
      },
      /** 编辑 */
      handleEdit(resolve, reject, location) {
        const params = { ...this.formData, opType: 1 };
        if (location) {
          params.projectDTO.latitude = location.latitude;
          params.projectDTO.longitude = location.longitude;
        }

        icProject
          .edit(params)
          .then(res => {
            console.log('编辑', res);
            this.$message({
              message: '提交成功',
              type: 'success',
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      },

      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    console.log('validate', o, valid);
                    if (valid) {
                      _resolve();
                    } else {
                      _reject(o);
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log('validate err1', err);
              reject(err);
            });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cus-inline-label {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #000;
  }
  .cus-inline-split {
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: #000;
  }
  .compete-wrap {
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    margin-bottom: 10px;
    .header {
      height: 38px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dcdfe6;
      padding: 0 10px;
      align-items: center;
      .compete-title {
        font-size: 12px;
        color: #000;
      }
      .compete-delete {
        cursor: pointer;
        font-size: 12px;
      }
    }
    .content {
      padding: 20px;
    }
  }
  .compete-add {
    height: 32px;
    border: 1px dashed rgb(217, 217, 217);
    color: #4d8cfd;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    .add-btn {
      font-size: 12px;
      color: #4d8cfd;
      margin-right: 4px;
    }
  }
</style>
