<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <div slot="title" class="cus-title-wrap">
        <div class="title">{{ drawerTitle }}</div>
        <div v-if="isEdit" class="status-wrap">
          <span class="sub-status">当前项目状态：</span>
          <span class="detail-status">{{ statusName }}</span>
        </div>
      </div>
      <template #footer>
        <div class="drawer-button-wrap">
          <div class="drawer-button-wrap-left">
            <template v-for="(btn, i) in drawerButtonsLeft">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
          <div class="drawer-button-wrap-right">
            <template v-for="(btn, i) in drawerButtonsRight">
              <ykc-button
                :type="btn.type"
                v-if="btn.enabled()"
                :disabled="btn.disabled()"
                :key="i"
                @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          v-if="drawerType === 'edit'"
          ref="addOrEdit"
          :id="id"
          :isEdit="isEdit"
          :active="stepsIndex"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import { icProject, treeUtils } from '@/service/apis';
  import { offlineExport, code } from '@/utils';
  import { loadDicItem } from '@/utils/dictionary';
  import { stationTypeData, PermAndCodeMap } from './constants';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        id: '',
        isRequest: false,
        statusName: '',
        status: '',
        cityData: [], // 城市数据源
        projectTypeData: [],
        drawerType: '',
        stepsIndex: 0,
        maxStepIndex: 3,
        isEdit: false,
        showDrawer: false,
        PermAndCodeMap,
        drawerButtonsLeft: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              console.log('formData', this.$refs.addOrEdit.formData);
              this.stepsIndex++;
            },
          },
        ],
        drawerButtonsRight: [
          {
            label: '保存',
            type: 'plain',
            enabled: () => {
              return this.isEdit;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              console.log('start', this.isRequest);
              this.$refs.addOrEdit
                .saveForm()
                .then(() => {
                  console.log('success', this.isRequest);
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .finally(() => {
                  this.isRequest = false;
                  console.log('finally', this.isRequest);
                });
            },
          },
          {
            label: '提交',
            enabled: () => {
              return this.status !== '10012' && this.status !== '10020' && this.status !== '10021';
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              console.log('submit btn');
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .catch(err => {
                  console.log('submit validate err', err);
                  if (err === 'ruleForm0') {
                    this.stepsIndex = 0;
                  }
                  if (err === 'ruleForm1') {
                    this.stepsIndex = 1;
                  }
                  if (err === 'ruleForm2') {
                    this.stepsIndex = 2;
                  }
                  if (err === 'ruleForm3') {
                    this.stepsIndex = 3;
                  }
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        pageButtons: [
          // {
          //   id: '0',
          //   text: '批量导入',
          //   type: 'plain',
          //   enabled: () => code('union:org:add'),
          //   handleClick: () => {
          //     this.add();
          //   },
          // },
          {
            id: '1',
            text: '新建项目',
            type: 'primary',
            enabled: () => code('project:icProject:list:add'),
            handleClick: () => {
              this.add();
            },
          },
          // {
          //   id: '2',
          //   text: '导出',
          //   type: 'plain',
          //   enabled: () => code('union:org:export'),
          //   handleClick: () => {
          //     this.doExportFile();
          //   },
          // },
        ],
        tableData: [],
        tableTitle: '项目列表',
        tableColumns: [
          { label: '项目编码', prop: 'projectNo', minWidth: '180px', fixed: true },
          { label: '初次接触时间', prop: 'startTime', minWidth: '180px' },
          { label: '站点名称', prop: 'destination', minWidth: '200px', showOverflowTooltip: true },
          { label: '项目状态', prop: 'statusName', minWidth: '100px' },
          {
            label: '建设状态',
            prop: 'investStatusName',
            minWidth: '120px',
            showOverflowTooltip: true,
          },
          {
            label: '站点性质',
            prop: 'stationTypeName',
            minWidth: '120px',
            showOverflowTooltip: true,
          },
          { label: '项目类型', prop: 'projectTypeName', minWidth: '100px' },
          { label: '省市区', prop: 'pcd', minWidth: '200px', showOverflowTooltip: true },
          {
            label: '详细地址',
            prop: 'detailAddress',
            minWidth: '200px',
            showOverflowTooltip: true,
          },
          { label: '项目来源', prop: 'projectSourceName', minWidth: '100px' },
          { label: '所属BD', prop: 'bdName', minWidth: '100px' },
          {
            label: '车位使用率',
            prop: 'parkingRate',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                if (!row.parkingRate) {
                  return '——';
                }
                return `${row.parkingRate}%`;
              },
            },
          },
          { label: '初次建议投建数量', prop: 'firstNum', minWidth: '120px' },
          { label: '投建模型建议投建数量', prop: 'num', minWidth: '140px' },
          { label: '创建时间', prop: 'createTime', minWidth: '180px' },
          { label: '创建人', prop: 'createBy', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              code('project:icProject:list:detail') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.detailLink(row);
                  }}>
                  详情
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code(PermAndCodeMap[row.status]) ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.add(row.id, row.statusName, row.status);
                  }}>
                  编辑
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code('project:icProject:list:delete') && ['10010', '10011'].includes(row.status) ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.handleDelete(row.id);
                  }}>
                  删除
                </ykc-button>
              ) : null,
          },
        ],
        pageInfo: {
          current: 0,
          size: 10,
        },
        searchParams: {
          projectNo: '',
          destination: '',
          stationType: '',
          cityId: [],
          date: [],
          projectType: '',
        },
      };
    },
    created() {
      this.searchTableList();
      // 获取城市下拉数据
      this.requestGetCity();
      this.projectTypeData = loadDicItem('support_project_type');
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量导入';
        }
        return `投建项目${this.isEdit ? '编辑' : '新增'}`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'projectNo',
            label: '项目编号',
            placeholder: '请输入项目编号',
          },
          {
            comName: 'YkcInput',
            key: 'destination',
            label: '站点名称',
            placeholder: '请输入站点名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationType',
            label: '站点性质',
            placeholder: '请选择站点性质',
            data: stationTypeData,
          },
          {
            comName: 'YkcCascader',
            key: 'cityId',
            label: '所属城市',
            placeholder: '请选择所属城市',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.cityData,
          },
          {
            key: 'date',
            isDateType: false,
            comName: 'YkcDatePicker',
            label: '创建时间',
            placeholder: '请选择创建时间',
          },
          {
            comName: 'YkcDropdown',
            key: 'projectType',
            label: '项目类型',
            data: this.projectTypeData,
          },
        ];
      },
    },
    methods: {
      /**
       * 获取城市数据
       */
      requestGetCity() {
        treeUtils
          .getDistrict({ level: '3' })
          .then(res => {
            const params = res.districts;
            this.cityData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      detailLink(row) {
        this.$router.push({
          path: '/projectManage/icProject/detail',
          query: {
            id: row.id,
          },
        });
      },
      handleDelete(id) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: `是否确认删除？`,
          onConfirm: done => {
            icProject
              .delete({ id })
              .then(() => {
                done();
                this.$message({
                  message: `删除成功`,
                  type: 'success',
                });
                this.searchTableList();
              })
              .catch(err => {
                console.log(err);
              });
          },
          onCancel: done => {
            done();
          },
        });
      },
      add(id, statusName, status) {
        this.stepsIndex = 0;
        this.maxStepIndex = 3;
        this.showAddDrawer = true;
        this.isEdit = !!id;
        this.id = id || null;
        this.statusName = statusName;
        this.status = status;
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const { cityId, date, destination, projectNo, projectType, stationType } =
          this.searchParams;
        const params = {
          cityId: cityId && cityId.length ? cityId[1] : '',
          createStartTime: date && date.length ? date[0] : '',
          createEndTime: date && date.length ? date[1] : '',
          destination,
          projectNo,
          projectType,
          stationType,
        };
        Promise.all([
          icProject.list({
            ...this.pageInfo,
            ...params,
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件
       * */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'zdl_org',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        console.log('form', this.searchParams, form);
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
  .drawer-button-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .cus-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .status-wrap {
      .sub-status {
        font-weight: normal;
      }
      .detail-status {
        color: red;
      }
    }
  }
</style>
